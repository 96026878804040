<template>
    <div>
      <b-row>
          <b-col class="col-md-6 col-12">
            <Breadcrumbs main="" title="Smart Url" class="showBreadcrumbs"/>
          </b-col>
        </b-row>
  
      <b-row class="mt-2">
        <b-col xl="12" md="12">
          <b-card title="" type="gradient-red" sub-title="" class="mb-4">   
            <b-row class="align-items-start">
                <b-col class="col-md-3 col-12">
                  <span class="custome-title">Smart Url</span>
                </b-col>
                <b-col class="col-md-9 col-12 d-flex justify-content-end align-items-start">
                  <b-form-group
                    label-cols="5"
                    label="Per page"
                    class="col-3 md-0 custom-font-18 text-right"
                    >
                    <b-form-select
                      class=" border custom-font-24"
                      v-model="perPage"
                      :options="pageOptions"
                      >
                    </b-form-select>
                  </b-form-group>
                  <button class="btn mr-2 mb-2 custom-btn-2" @click="open_import_smartUrl = true">
                    Bulk Import Smart URL </button>
                  <button class="btn mb-2 custom-btn" @click="OpenSmarturl()">
                    Add Smart URL</button>
                </b-col>
              </b-row>
            <div class="table-responsive datatable-vue">
              <b-table
              class="text-left custom-font-16" 
              :fields="smartUrlsFields"
              :current-page="currentPage"
              :per-page="perPage"
              striped hover 
              :items="smartUrlsData" 
              :busy="isBusy" 
              stacked="md" 
              >
                <template #cell(country)="row">
                    <span v-for="(data,i) in row.item.country" :key="i">
                      <span v-if="countryOpt.filter(t=> t.id == data).length > 0" class="badge custom-badge custom-font-18 ml-0">{{ countryOpt.filter(t=> t.id == data)[0].name }}</span>
                      <span v-else> {{ data }} </span>
                    </span>
                </template>
                <template #cell(status)="row">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                  </div>
                </template>
                <template #cell(isProcessed)="row">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.isProcessed disabled>
                        <span class="switch-state" :class="row.item.isProcessed?'bg-success':'bg-danger'"></span>
                        </label>
                  </div>
                </template>
                <template #cell(url)="row">
                  <span target="blank">
                    {{ row.item.url }}
                  </span>

                </template>
                <template #cell(action)="row">
                    <button
                      size="sm"
                      @click="EditSmarturl(row.item)"
                      class="btn text-blue btn-xs mr-1"
                    >
                    <feather type="edit"></feather>
                    </button>
                    <button
                      size="sm"
                      @click="deleteSmartUrls(row.item._id)"
                      class="btn text-danger btn-xs"
                    >
                    <feather type="trash-2"></feather>
                    </button>
                  </template>
                  <template #table-busy>
                    <div class="col-md-12" >
                      <h6 class="sub-title mb-0 text-center">Loading...</h6>
                      <div class="loader-box">
                        <div class="loader-30"></div>
                      </div>
                    </div> 
                  </template>
              </b-table>
            </div>
            <b-col class="mt-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="mt-0 custom-font-16"
              ></b-pagination>
            </b-col>
       
          </b-card>
        </b-col>
      </b-row>
  
      <b-modal
        v-model="smarturl_model"
        id="smart-url"
        size="lg"
        title="Smart Url"
        class="theme-modal"
        no-close-on-backdrop
        hide-footer
        @hide="getSmarturls()"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-12">
              <b-card class="p-2">
                <form @submit.prevent="Submit()" action="">
                <div class="row">
                  <label>Name</label>
                  <b-form-input
                    v-model="input.name"
                    placeholder="Enter Name"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>URL</label>
                  <b-form-input
                    v-model="input.url"
                    placeholder="Enter URL"
                    required
                  ></b-form-input>
                </div>
                <div class="row mt-2">
                  <label>Country</label>
                  <multiselect
                    :options="countryOpt"
                    label="name"
                    track-by="id"
                    v-model="countryArray"
                    placeholder="Select Country"
                    :multiple="true"
                    @select="assignCountry"
                  >
                  </multiselect>
                </div>
                <div class="row mt-4 d-flex justify-content-center">
                  <b-button
                    :disabled="isBusy"
                    type="submit"
                    class="col-md-3 col-12"
                    variant="primary"
                    >{{ editSmarturl?'Edit':'Submit'}}</b-button
                  >
                </div>
              </form>
              </b-card>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Bulk Smart URL Import Start  -->
      <b-modal
        id="import-monitor"
        size="xl"
        title="Import bulk smart URL"
        class="theme-modal"
        v-model="open_import_smartUrl"
        no-close-on-backdrop
        hide-footer
      >
        <input type="file" id="files" accept=".csv" ref="smartUrlFile" />
        <span v-if="loading">
          <h6 class="sub-title mb-0 text-center">Uploading...</h6>
          <div class="loader-box">
            <div class="loader-30"></div>
          </div>
        </span>
        <span v-else>
          <b-button
            size="sm"
            variant="success"
            class="btn-pill"
            @click="UploadCsv()"
            >Upload</b-button
          >
        </span>
        <div class="p-3">
          <b-row class="mt-2">
            <b-col class="col-md-12">
              <div class="f-20 f-w-600 link_txt" @click="downloadCSV">
                Download file template
              </div>
            </b-col>
          </b-row>
        </div>
        <template #modal-footer>
          <b-button @click="open_import_smartUrl = false" color="danger"
            >Close</b-button
          >
        </template>
      </b-modal>
      <!-- Bulk Smart URL Import End  -->
    </div>
  </template>
  
  
  
<script>
  import SmartUrlApi from "../../../../services/smarturlService";
  import NetworkTypeService from '../../../../services/NetworkTypeService'
  import Multiselect from "vue-multiselect";
  import config from "../../../../services/globalLit";
  import SampleCsv from "../../../../services/sampleCsvData";
  export default {
    name: "ServiceMapping",
    components: {
      Multiselect,
    },
    data() {
      return {
        countryOpt:[{ "id": '@@@', "name": "All country" }],
        input: {
          name: '',
          url: '',
          country: [],
        },
        countryArray:[],
        services: [],
        smartUrlsData: [],
        smarturl_model: false,
        isBusy: false,
        smartUrlsFields: [
          { key: "name", label: "Name", class: "font-weight-bold" },
          { key: "url", label: "Url" },
          { key: "country", label: "Country", sortable: true},
          { key: "isProcessed", label: "Is Processed" },
          { key: "status", label: "Status", sortable: true},
          {key: "createdAt", label: "Created At", sortable: true},
          { key: "action", label: "Action"}
        ],
        open_import_smartUrl: false,
        sampleData: [],
        sampleSmartUrlfields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 20,
        pageOptions: [20,50, 100, 200],
        editSmarturl:false,
        loading: false,
      };
    },
    mounted() {
      this.getCountry();
      this.getSmarturls();
    },
    methods: {
      async getSmarturls() {
        this.input = {name: '',url: '',country: ""};
        let response = await SmartUrlApi.getSmarturls();
        if (response.result) {
          this.smartUrlsData = response.data;
          this.totalRows = this.smartUrlsData.length
        }
      },
      async Submit() {
        if(this.validate()){
        this.isBusy = true;
        var response
        var payload = {name:this.input.name,url:this.input.url}
        payload.country = this.countryArray.map(element => {
          return element.id
        });
        if(this.editSmarturl){
           payload  = {
            "suid":this.input._id,
            "updateObj": payload
          }
           response = await SmartUrlApi.editSmarturl(payload);
           this.editSmarturl = false;
        }else{
           response = await SmartUrlApi.addSmarturl(payload);
        }
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            this.smarturl_model = false;
            this.getSmarturls();
            this.isBusy = false;
          }
        });
      }
      },
      validate(){
        if(this.input.url == "" || config.isValidUrl(this.input.url) == false){
          this.$toasted.show("Please enter valid url", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return false;
        }
        if(this.countryArray.length == 0){
          this.$toasted.show("Please select country", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return false;
        }
        return true;
      },
      OpenSmarturl() {
        this.input = {
          name: '',
          url: '',
          country: [],
        };
        this.editSmarturl = false;
        this.smarturl_model = true;
      },
     async deleteSmartUrls(id){
      if(confirm("Are you sure you want to delete this smart url?")){
        let payload = {"suid":id}
        let response = await SmartUrlApi.deleteSmartUrls(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
        this.getSmarturls();
        } 
      },
      EditSmarturl(data){
        this.editSmarturl = true;
        this.input = data;
        this.countryArray = [];
        this.countryArray= this.input.country.map(element => {
          return this.countryOpt.filter(t=> t.id == element)[0]
        });
        this.smarturl_model = true;
      },
      async getCountry(){
        let response = await NetworkTypeService.getCountry();
        this.countryOpt = [{ "id": '@@@', "name": "All country" }];
        response.data.forEach(element => {
          this.countryOpt.push({id:element.alpha_3,name:element.name})
        });
      },
      assignCountry(){
        if (this.countryArray.some(t => t.id === '@@@')) {
          this.countryArray = [{ id: '@@@', name: 'All country' }];
        }
      },
      async changeStatus(data){
        if (data.status == false) {
            data.status = true;
          } else {
            data.status = false;
          }
        let payload = {
          suid: data._id,
          updateObj: {
            status: data.status,
          },
        };
        let response = await SmartUrlApi.editSmarturl(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
      },

      async UploadCsv() {
        this.loading = true;
        if (this.$refs.smartUrlFile.files[0]) {
          var file = this.$refs.smartUrlFile.files[0];
          const formData = new FormData();
          formData.append("smartlinkcsv", file);
          let response = await SmartUrlApi.csvUpload(formData);
          if (response.result) {
            this.$toasted.show(response.message, {
              theme: "outline",
              position: "bottom-center",
              type: "success",
              duration: 2000,
            });
            this.open_import_smartUrl = false;
            this.getSmarturls()
          } else {
            this.$toasted.show(response.message, {
              theme: "outline",
              position: "bottom-center",
              type: "error",
              duration: 2000,
            });
          }
        } else {
          this.$toasted.show("Please select file", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
        this.loading = false;
      },
      downloadCSV() {
        this.sampleSmartUrlfields = SampleCsv.sampleSmarturlFields;
        const data = [this.sampleSmartUrlfields];
        const csvContent =
          "data:text/csv;charset=utf-8," +
          data.map((e) => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "TemplateBulkImportFile.csv");
        document.body.appendChild(link);
        link.click();
      },
      downloadSampleData() {
        this.sampleData = SampleCsv.sampleSmarturlData;
        config.downloadCSV(this.sampleData);
      },
    },
  };
</script>
<style scoped>


.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>